import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="about-us">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="About Us" text="At Nialkyn, we believe sports have the unique power to inspire, connect, and transform. As a premier sports management and marketing company, we specialize in representing world-class talent, maximizing the value of sports assets, organizing landmark events, securing high-impact sponsorships, and creating game-changing brand strategies.

Our goal is simple: to create lasting value for athletes, investors, and sports brands by bridging the gap between talent and opportunity. With deep expertise, a global network, and a passion for innovation, we are shaping the future of sports—one partnership at a time." />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">The Power Behind Our Athletes</h1>
      <p>Explore the Library</p>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Kesrick Williams" text="Kesrick Williams is the first international cricketer from St Vincent to represent West Indies. " />
      <Feature title="Mink Peeters" text="Mink Peeters is a Dutch Footballer who has played for the likes of Real Madrid and Ajax." />
      <Feature title="Nishan Peiris" text="Kurukulasooriya Nishan Peiris is a professional Sri Lankan cricketer currently plays Test cricket for the national team." />
    </div>
  </div>
);

export default WhatGPT3;