import React from "react";
import './possibility.css'
import possibilityImage from '../../assets/medal.png'
const Possibility = () => {
    return (
        <div className="gpt3__possibility section__padding" >
            <div className="gpt3__possibility-image">
                <img src={possibilityImage} alt="" />
            </div>
            <div className="gpt3__possibility-content">
                {/* <h4>Request Early Access to Get Started</h4> */}
                <h1 className="gradient__text" id="why_nialkyn">Why Partner With Nialkyn?</h1>
                <p>We combine passion with precision. Our deep knowledge of the sports landscape, combined with data-driven insights, means we deliver exceptional results. Here's why our partners trust us to deliver every time</p>
                {/* <h4>Request Early Access to Get Started</h4> */}
            </div>
        </div>
    )
}

export default Possibility