import React from "react";
import Feature from '../../components/feature/Feature';
import './features.css';


const featuresData = [
    {
        title: 'Talent Representation',
        text: 'Empowering athletes to reach their full potential, we manage careers with a focus on long-term success. From contract negotiations to brand endorsements, we strategically position our talent for growth both on and off the field.',
      },
      {
        title: 'Sports Assets Management',
        text: 'We expertly manage sports properties, ensuring their value grows through strategic investments, partnerships, and efficient operations. Our goal is to maximize returns while preserving the integrity of the asset.',
      },
      {
        title: 'Event Management & Sponsorships',
        text: 'From high-stakes global tournaments to exclusive sponsor activations, we handle every detail of event management with precision. Our expertise in securing meaningful sponsorships ensures a win-win for brands and events alike',
      },
      {
        title: 'Sports Investment',
        text:'We identify high-potential sports investment opportunities, guiding investors toward ventures that align with both passion and profit. Our data-driven approach ensures smart, impactful investments that yield lasting returns.',
    },
]

const Features = () => (
    <div className="gpt3__features section__padding" id="expertise">
      <div className="gpt3__features-heading">
        <h1 className="gradient__text">Our Expertise—Where Strategy Meets Success</h1>
        <p>Request Early Access to Get Started</p>
      </div>
      <div className="gpt3__features-container">
        {featuresData.map((item, index) => (
          <Feature title={item.title} text={item.text} key={item.title + index} />
        ))}
      </div>
    </div>
  );

export default Features