import React from "react";
import "./header.css";
import people from "../../assets/people.png";
// import ai from "../../assets/ai.png";
import player from "../../assets/388171.webp"
// import cover from "../../assets/cover.jpg"

const Header = () => {
  return (
    <div className="gpt3__header section__padding" id="home">
      <div className="gpt3__header-content">
        <h1 className="gradient__text">
        Pushing Boundaries, Connecting Talent,<br></br> Building Brands
        </h1>
        <p>
          Your Gateway to Sports Success—From Talent Representation to Unrivaled Sports Investments.
        </p>
        <div className="gpt3__header-content__input">
          {/* <input type="email" placeholder="Your Email Address" /> */}
          <button type="button"><a href="mailto:info@nialkyn.com">Get in Touch</a></button>
        </div>
        <div className="gpt3__header-content__people">
          <img src={people} alt="people whe want to acces this service" />
          <p>Nialkyn is growing rapidly!</p>
        </div>
      </div>
      <div className="gpt3__header-image">
        <img src={player} alt="ai" />
        <p>Nishan Peiris, managed by Nialkyn, took nine wickets on his debut against New Zealand in September 2024.</p>
      </div>
    </div>
  );
};

export default Header;
